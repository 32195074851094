/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Authentication layout components
import CoverLayout from "pages/Authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import logoBaally from "assets/logos/logoBaally.png";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { HttpService } from "Api-Services/httpService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BasicLayout from "pages/Authentication/components/BasicLayout";
import validator from "validator";
import MKAvatar from "components/MKAvatar";

function Cover() {
  const [forceRerender, setForceRerender] = React.useState(true);
  //local constants
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [selectedUserType, setSelectedUserType] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [invalidResponse, setInvalidResponse] = useState("");
  const [loginResponse, setLoginResponse] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [repeatUserEmail, setRepeatUserEmail] = useState("");
  const [gender, setGender] = useState("male");
  const [sectionNo, setSectionNo] = useState(1);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const history=useHistory()

  const navigate = useNavigate();

  const goToStep = async (stepNo) => {
    if (validateForm(stepNo - 1)) {
      setSectionNo(stepNo);
    }
  };
  const goToVarification = async () => {
    if (loginResponse) {
      if (loginResponse.success) {
        if (loginResponse.record) {
          if (loginResponse.record.link) {
            window.open(loginResponse.record.link);
          }
        }
      }
    }
  };

  const validateForm = (validationStepNo) => {
    let valid = true;
    let message = "";
    setLoginResponse(null);

    setInvalidResponse("");
    setIsFormValid(true);
    if (userEmail && repeatUserEmail && firstName && lastName) {
      if (!validator.isEmail(userEmail)) {
        message = "Email is not valid";
        valid = false;
      } else if (userEmail != repeatUserEmail) {
        message = "Email not match";
        valid = false;
      }
    } else {
      message = "Fill all the inputs correctly!";
      valid = false;
    }
    if (validationStepNo > 1) {
      if (newPassword && confirmPassword) {
        if (confirmPassword == newPassword) {
          if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$/.test(newPassword)) {
            // valid password
          } else {
            message = `Password is weak , put at least 8 characters combination of letter, number and special character, `;
            valid = false;
          }
        } else {
          message = "Password not match";
          valid = false;
        }
      } else {
        message = "Fill all the inputs correctly!";
        valid = false;
      }
    }
    setInvalidResponse(message);
    setIsFormValid(valid);

    return valid;
  };

  const register = async () => {
    // localStorage.clear();
    let valid = false;

    if (validateForm(2)) {
      valid = true;
    }
    // console.log("userEmail&&password",userEmail,password, valid)
    if (valid) {
      setLoading(true);
      let data = {
        password: newPassword,
        email: userEmail,
        firstName: firstName,
        lastName: lastName,
        sex: gender,
      };
      let relativePath = "/api/authorization/registernewuser";
      // let AdditionalData={withCredentials: true, credentials: 'include'}//{  credentials: 'include' }//header can be included here
      let registerNewUserRes = await postDocuments("registernewuser", relativePath, data, {});
      if (registerNewUserRes) {
        setLoginResponse(registerNewUserRes);
        if (registerNewUserRes.success) {
          setSectionNo(3);
          if (registerNewUserRes.record) {
            if (registerNewUserRes.record.link) {
              window.location.assign(registerNewUserRes.record.link);
            }
          }
        }
      }
    }
    setLoading(false);
  };

  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    setLoading(true);
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    setLoading(false);
    if (documentsResponse) {
      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
      if (documentsResponse.success) {
        responseD.success = true;
      }
    }
    setForceRerender(!forceRerender);
    return responseD;
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          pb={2}
          mb={1}
          textAlign="center"
        >
          {/* <MKBox mb={{ xs: -7, md: -7 }}>
            <MKAvatar src={logoBaally} alt="Baally" size="xl" shadow="xl" />
          </MKBox> */}
          <MKTypography variant="h4" fontWeight="medium" color="white" my={1}>
            DCE{" "}
          </MKTypography>
        </MKBox>
        <MKBox p={1} textAlign="center">
          <MKBox component="form" role="form" mx={1}>
            <MKTypography display="block" variant="h5" mb={1}>
              Register as a new student
            </MKTypography>
            <Divider sx={{ my: "0px" }} />
            {sectionNo == 1 && (
              <Grid container spacing={2} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12}>
                  <MKInput
                    type="text"
                    label="First Name"
                    error={!(isFormValid || firstName)}
                    defaultValue={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    type="text"
                    label="Last Name"
                    error={!(isFormValid || lastName)}
                    defaultValue={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    type="email"
                    label="Email"
                    defaultValue={userEmail}
                    error={!(isFormValid || userEmail)}
                    onChange={(e) => {
                      setUserEmail(e.target.value);
                    }}
                    fullWidth
                  />
                </Grid>
                {userEmail && (
                  <Grid item xs={12}>
                    <MKInput
                      type="email"
                      label="Re-Enter Email"
                      defaultValue={repeatUserEmail}
                      error={!(isFormValid || repeatUserEmail)}
                      onChange={(e) => {
                        setRepeatUserEmail(e.target.value);
                      }}
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {/* <MKTypography
                  display="block"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  mb={1}
                >
                  Gender
                </MKTypography> */}
                  <Autocomplete
                    defaultValue={gender}
                    error={!(isFormValid || gender)}
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                    options={["male", "female"]}
                    renderInput={(params) => <MKInput {...params} label="Gender" />}
                  />
                </Grid>
              </Grid>
            )}

            {sectionNo == 2 && (
              <MKBox pt={4}>
                <MKBox component="form" role="form">
                  <MKBox mb={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                      <OutlinedInput
                        fullWidth
                        variant="outlined"
                        id="input-with-icon-textfield"
                        label="New Password"
                        error={
                          !(
                            isFormValid ||
                            (confirmPassword ? confirmPassword != newPassword : false)
                          )
                        }
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MKBox>
                  <MKBox mb={4}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        fullWidth
                        variant="outlined"
                        id="input-with-icon-textfield"
                        label="Confirm Password"
                        error={
                          !(isFormValid || (newPassword ? newPassword != confirmPassword : false))
                        }
                        onChange={(e) => {
                          setConfirmPassword(e.target.value);
                        }}
                        type={showConfirmPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </MKBox>
                  {/* <MKBox mt={6} mb={1}>
              <MKButton disabled={newPassword?((newPassword==confirmPassword)?false:true):true} variant="gradient" color="info"  
               onClick={()=>{resetPassword()}} fullWidth>
                reset
              </MKButton>
            </MKBox> */}
                </MKBox>
              </MKBox>
            )}

            {(loginResponse || !isFormValid) && (
              <MKBox mt={1} mb={1} textAlign="center">
                {loginResponse && (
                  <Typography
                    variant="body2"
                    color={(loginResponse ? loginResponse.success : false) ? "green" : "red"}
                  >
                    {" "}
                    <small>{loginResponse.message}</small>
                  </Typography>
                )}
                {!isFormValid && (
                  <Typography variant="body2" color={"red"}>
                    <small>{invalidResponse}</small>
                  </Typography>
                )}
              </MKBox>
            )}

            <MKBox mt={3} mb={1}>
              {sectionNo == 1 ? (
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                  <MKButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      goToStep(sectionNo + 1);
                    }}
                    // fullWidth
                  >
                    Next
                  </MKButton>
                </Grid>
              ) : sectionNo == 2 ? (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-end"
                  >
                    <MKButton
                      style={{ marginRight: "12px" }}
                      variant="outlined"
                      color="info"
                      size="small"
                      onClick={() => {
                        goToStep(sectionNo - 1);
                      }}
                      // fullWidth
                    >
                      Back
                    </MKButton>

                    <MKButton
                      variant="gradient"
                      color="success"
                      size="small"
                      onClick={() => {
                        register();
                      }}
                      // fullWidth
                    >
                      Register
                    </MKButton>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="flex-end"
                  >
                    <MKButton
                      variant="gradient"
                      color="success"
                      size="small"
                      onClick={() => {
                        goToVarification();
                      }}
                      // fullWidth
                    >
                      Continue
                    </MKButton>
                  </Grid>
                </>
              )}
            </MKBox>
            {loading && (
              <MKBox mt={3} mb={1} textAlign="center">
                <CircularProgress size={24} />{" "}
              </MKBox>
            )}

            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Login
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
