import axios from "axios";
var CryptoJS = require("crypto-js");
export class HttpService {
  static decryptResponseData = (data) => {
    let decryptedData;
    // Decrypt
    try {
      if (typeof data == "staaring") {
        var bytes = CryptoJS.AES.decrypt(data, "secret key 123");
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        //  console.log(decryptedData,"decryptedData http get")
      } else if (typeof data == "object") {
        decryptedData = data;
      }
    } catch (error) {
      return false;
    }
    return decryptedData;
  };

  static getHeaderAndURL = () => {
    // let baseURL = "https://aks-cluster-dev.ethiopianairlines.com/"; //Development URL
    // let baseURL = "https://api.bfamilyfilmschool.com"; // Production URL
    let baseURL = ""; // Production URL
    // baseURL="https://contentapi.salemstudio.bfamily.et"; // Production URL
    // baseURL="https://api2.dillatownadministration.gov.et"; // Production URL//
    baseURL = "https://mainapi.dce.baally.com"; // Production URL
    // baseURL="http://contentapi.baliwebsites.bfamily.et"; // Production URL
    // baseURL="http://api.dillaketemaastedader.bfamily.et"; // Production URL
    // contentapi.salemstudio.bfamily.et
    // let baseURL="https://api.dillaketemaastedader.bfamily.et/"; // Production URL
    if (window.location.hostname.includes("localhost")) {
      baseURL = "http://localhost:8000"; //Development URL
    }

    let token = "";
    // let token = localStorage.getItem("holidaysToken");

    let headers = {
      // Authorization: "Bearer " + token, //the token is a variable which holds the token
      // IsDevelopment: true,
      // IsDevelopment: false,
      ContentType: "application/json",
      // Accept: "application/json",
    };
    return {
      baseUrl: baseURL,
      token: token,
      headers: headers,
      // withCredentials:{
      //   // withCredentials: true, credentials: 'include'
      // }
    };
  };

  static getService = async (relativePath, AdditionalData, queryString = "") => {
    let response = { success: false, data: null, message: "" };
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl; //"https://api.bfamilyfilmschool.com/";
    let allAdditonalData = {};
    allAdditonalData = AdditionalData;
    allAdditonalData.headers = headerAndURL.headers;

    // allAdditonalData.withCredentials=headerAndURL.withCredentials;
    (allAdditonalData.withCredentials = true), (allAdditonalData.credentials = "include");
    // console.log(allAdditonalData,"  http allAdditonalData")
    try {
      let responseD = await axios.get(baseUrl + relativePath, allAdditonalData);
      //  .then(function (responseD) {
      // console.log(responseD,"res http get")
      let res = responseD;
      let dataD = this.decryptResponseData(res.data);
      // console.log(dataD,"res dataD get")
      res.data = dataD;
      if (res.status == 200) {
        response.success = res.data.success;
        response.message = res.data.message;
        response.data = res.data;
      } else {
        response.success = false;
        response.message = "Problem occured during fetch data";
      }
    } catch (error) {
      // console.log(error,"error http get")
      response.success = false;
      response.error = error;
      response.message = "Problem occured during fetch dataaaa";
    }

    return response;

    0;
  };

  static postService = async (relativePath, requestData, AdditionalData, queryString = "") => {
    let response = { success: false, data: null, message: "" };
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl; //"https://api.bfamilyfilmschool.com/";
    let allAdditonalData = {};
    allAdditonalData = AdditionalData;
    allAdditonalData.headers = headerAndURL.headers;
    (allAdditonalData.withCredentials = true), (allAdditonalData.credentials = "include");
    // allAdditonalData.withCredentials=headerAndURL.withCredentials;

    // let baseUrl = headerAndURL.baseUrl + "flightapi-etholidays/V1.0/api/";
    // axios.post(baseUrl+"api/photos", formData,additionalData)

    // console.log(allAdditonalData,"req allAdditonalData")
    try {
      let responseD = await axios.post(
        baseUrl + relativePath + queryString,
        requestData,
        allAdditonalData
      );
      // .then(function (responseD) {

      // console.log(responseD,"res http post")
      let res = responseD;
      let dataD = this.decryptResponseData(res.data);
      // console.log(dataD,"res dataD get")

      res.data = dataD;

      if (res.status == 200) {
        response.success = res.data.success;
        response.message = res.data.message;
        response.data = res.data;
      } else {
        response.success = false;
        response.message = "Problem occured during fetch data";
      }
    } catch (error) {
      response.success = false;
      response.error = error;
      response.message = "Problem occured during fetch dataaaa";
    }

    return response;
  };

  static getToken = () => {
    let baseUrl = this.getHeaderAndURL().baseUrl;
    let tokenApiUrl = "authorizationapi-marketplace/api/OAuth/Generate";
    return axios.post(
      baseUrl + tokenApiUrl,
      { Username: "me@gmail.com", Password: "123456" },
      {
        headers: {
          isdevelopment: "true",
          // isdevelopment: "false",
          ContentType: "application/json",
          Accept: "application/json",
        },
      }
    );
  };

  static checkToken() {
    const token = localStorage.getItem("holidaysToken");

    if (token == null || token == "null") {
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    } else {
      //  var decodedToken=jwt.decode(token, {complete: true});
      // var dateNow = new Date();
      // console.log(decodedToken);
      // console.log(decodedToken.payload.exp);
      // console.log(dateNow.getTime());
      //  if(decodedToken.payload.exp < dateNow.getUTCSeconds())
      //  {
      //   return this.getToken().then((response) => {
      //      let newToken =  response.data.token;
      //      localStorage.setItem("holidaysToken", newToken);
      //    })
      //  }
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    }
  }
}
