import axios from "axios";
import { HttpService } from "./httpService";

export class UseAuth {
  // static history=useHistory()
  static getHeaderAndURL = () => {
    let baseURL = "https://api.bfamily.et/"; // Production URL
    let token = localStorage.getItem("token");

    let headers = {
      Authorization: "Bearer " + token, //the token is a variable which holds the token
      // IsDevelopment: true,
      IsDevelopment: false,
      ContentType: "application/json",
      Accept: "application/json",
    };

    return {
      baseUrl: baseURL,
      token: token,
      headers: headers,
    };
  };

  static verifyUser = async () => {
    let res = false;
    let relativePath = "/api/auth/isUserLoggedIn";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    // console.log("verifyUser req", relativePath, AdditionalData);
    try {
      await HttpService.getService(relativePath, AdditionalData).then(
        function (Resp) {
          if (Resp.success) {
            res = true;
          } else {
            // let path = "/auth/login-page";
            // window.location.assign(path);
          }
        },
        function (error) {
          res = false;
          let path = "/auth/login-page";
          // window.location.assign(path);
        }
      );
    } catch (error) {
      res = false;
    }
    // console.log("verifyUser http Resp", res);
    return res;
  };

  static verifyWebsite = async () => {
    let res = false;
    let relativePath = "/api/auth/isWebsiteLoggedIn";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    // console.log("verifyUser req",relativePath,AdditionalData)
    try {
      await HttpService.getService(relativePath, AdditionalData).then(
        function (Resp) {
          if (Resp.success) {
            res = true;
          }
        },
        function (error) {
          res = false;
        }
      );
    } catch (error) {
      res = false;
    }
    return res;
  };

  static logout = async () => {
    let relativePath = "/api/authorization/logout";
    let AdditionalData = {}; //{  credentials: 'include' }//header can be included here
    await HttpService.getService(relativePath, AdditionalData).then(
      function (Resp) {
        // localStorage.clear();
        if (Resp.success) {
          let path = "/";
          window.location.assign(path);
        }
      },
      function (error) {
        // console.log("usertype")
        // console.log(error)
      }
    );
  };

  static login = async (data) => {
    await HttpService.getFirebaseDoc;
    const response = await fetch("localhost:3000/api/auth/user", {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const content = await response.json();
    setName(content.name);
  };

  static loginToWebsite = async (websiteData) => {
    // console.log(websiteData,"auth websiteData")
    let resp = { success: false, message: "not success", record: null };
    let data;
    let relativePath = "/api/authorization/logintowebsite";
    if (websiteData.websiteId) {
      // new ||new
      data = {
        websiteId: websiteData.websiteId,
        // "userId":websiteData,
        // "command":"new"
        callerSite: "platformadminportal",
      };
    }

    let addUserToWebsiteRes = await this.postDocuments("loginToWebsite", relativePath, data);
    if (addUserToWebsiteRes.success) {
      localStorage.clear();
      let path = "/";
      window.location.assign(path);
    }
    return resp;
  };

  static switchProfileById = async (id,navPath) => {
    // console.log(websiteData,"auth websiteData")
    let resp = { success: false, message: "not success", record: null };
    let data;
    let relativePath = "/api/authorization/logintoprofile";
    if (id) {
      // new ||new
      data = {
        ProfileId: id,
        // "userId":websiteData,
        // "command":"new"
        callerSite: "platformadminportal",
      };
    }

    let addUserToWebsiteRes = await this.postDocuments("loginToWebsite", relativePath, data);
    if (addUserToWebsiteRes.success) {
      // let path =navPath|| "/";
      // window.location.assign(path);
      resp.success=true
      resp.record=addUserToWebsiteRes.record 
    }
    return resp;
  };


  static getIPService = (ipData) => {
    // const publicIp = require("public-ip");
    const publicIp = "";
    let headerAndURL = this.getHeaderAndURL();
    let baseUrl = headerAndURL.baseUrl + "bfam.et/api/";

    var relativePath2 = "IpDetail/Get?IpAddress=" + ipData;
    return axios.get(baseUrl + relativePath2, {
      headers: headerAndURL.headers,
    });
  };

  static getToken = () => {
    let baseUrl = this.getHeaderAndURL().baseUrl;
    let tokenApiUrl = "bfam/api/OAuth/Generate";
    return axios.post(
      baseUrl + tokenApiUrl,
      { Username: "me@gmail.com", Password: "123456" },
      {
        headers: {
          isdevelopment: "true",
          // isdevelopment: "false",
          ContentType: "application/json",
          Accept: "application/json",
        },
      }
    );
  };

  static checkToken() {
    const token = localStorage.getItem("token");

    if (token == null || token == "null") {
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    } else {
      //  var decodedToken=jwt.decode(token, {complete: true});
      // var dateNow = new Date();
      // console.log(decodedToken);
      // console.log(decodedToken.payload.exp);
      // console.log(dateNow.getTime());
      //  if(decodedToken.payload.exp < dateNow.getUTCSeconds())
      //  {
      //   return this.getToken().then((response) => {
      //      let newToken =  response.data.token;
      //      localStorage.setItem("holidaysToken", newToken);
      //    })
      //  }
      return this.getToken().then((response) => {
        let newToken = response.data.token;
        // localStorage.setItem("holidaysToken", newToken);
      });
    }
  }

  static postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = await HttpService.postService(relativePath, data, {}, "");
    // console.log("documentsResponse ssss",relativePath,data  ,documentsResponse)
    if (documentsResponse.success) {
      responseD.success = true;
      if (documentsResponse.data) {
        responseD.record = documentsResponse.data.record;
        responseD.message = documentsResponse.data.message;
      }
    } else if (documentsResponse.data) {
      responseD.message = documentsResponse.data.message;
    }
    return responseD;
  };
}
