import { HttpService } from "../Api-Services/httpService";
import { createContext, useEffect, useState, useContext } from "react";
import { DataManagementService } from "../Api-Services/dataManagementService";
// import { SideBarRoutes } from 'sideBarRoutes';
import { UseAuth } from "Api-Services/useAuth";
import groupArray from "group-array";

const CurrentUserContext = createContext();
//now we have a context object

function CurrentUserProvider(props) {
  //create the provider and its functionality
 
 
  const [currentUser, setCurrentUser] = useState({});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [educationLevels, setEducationLevels] = useState([]);
  const [websiteFiles, setWebsiteFiles] = useState({});
  const [userFiles, setUserFiles] = useState({});
  const [jobs, setJobs] = useState([]); //
  const [myAgencies, setMyAgencies] = useState([]);
  const [myBusinesses, setMyBusinesses] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [employmentAgencyPaymentAccounts, setEmploymentAgencyPaymentAccounts] = useState([]);
  const [newJobSeekers, setNewJobSeekers] = useState([]);
  const [myJobSeekerInformation, setMyJobSeekerInformation] = useState(null);
  const [myAgencyJobSeekerInformation, setMyAgencyJobSeekerInformation] = useState(null);
  const [isAgentWebsite, setIsAgentWebsite] = useState(false);
  const [agencyData, setAgencyData] = useState(null);
  const [websitePagesContent, setWebsitePagesContent] = useState(null);
 
  const [forceRerender, setForceRerender] = useState(true);
 
  useEffect(() => {
    // setWebsitePagesContent(websitePagesContentD)
    getStarted();
  }, []);

  const getStarted = async () => {
    // getWebsiteContentTypes()
    try {
      let agencyDataD=null
      let isUserLoggedInD = await UseAuth.verifyUser();
      let isWebsiteLoggedIn = await UseAuth.verifyWebsite(); 
      setIsUserLoggedIn(isUserLoggedInD);
      // getEducationLevels();
      // getJobs();
      // if(false){
      // if(1){
      //  let agencyDataD2 =await getAgentDataForWebsite_byWebsiteDomain()
      //   //  agencyDataD2=await getAgentDataForWebsite_byId(agentId)
       
      //  if(agencyDataD2){
      //   if(agencyDataD2.id){
      //     agencyDataD=agencyDataD2
      //     getPaymentMethods()
      //     //  getNewJobSeekers(agencyDataD.id)
      //   }
      //  }
      // }

      if (isUserLoggedInD) {
        
        getMyBusinesses()
        getCurrentUser();
        getCurrentProfile();
        // console.log("agencyDataD",agencyDataD)
        // getMyAgencies();
        // getMyWebsites()
        // getUserFiles();
      } 
      
    } catch (error) {
      
    }
  };


  // const assignInitializeDatas = (initData) => { 
  //   try {
  //   if(initData){
  //     if(initData.favIcon){
  //     // console.log(initData,"init assign")
  //     // websiteFavIcon
  //     let hd = document.getElementById('htmlheadtag1');  
  //     let  itm = document.createElement('link');
  //     itm.id = 'whatever';
  //     itm.rel = 'shortcut icon';
  //     itm.type = 'image/png';
  //     itm.href = initData.favIcon
  //     hd.appendChild(itm);
  //     // console.log("head tags",hd)
  //     }
  //   }
      
  //   } catch (error) {
  //     // console.log("head tags",error)
      
  //   }
  // }
  const getCurrentProfile = () => {
    let mydetails;
    getDocumentsAll("myprofile", "/api/profile/myprofile").then((mydetailsRes) => {
      if (mydetailsRes.success) {
        mydetails = mydetailsRes.record;
        setCurrentUser(mydetails);
      }
      // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    });
  };


  const getCurrentUser = () => {
    // let mydetails;
    // getDocumentsAll("mydetails", "/api/auth/mydetails").then((mydetailsRes) => {
    //   if (mydetailsRes.success) {
    //     mydetails = mydetailsRes.record;
    //     setCurrentUser(mydetails);
    //   }
    //   // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    // });
  };

  
  // const getEmploymentAgencyPaymentAccounts = (id) => {
  //   let mydetails;
  //   let agentId=id
  //   if(!id){
  //     if(agencyData){
  //       if(agencyData.id){
  //        agentId=agencyData.id
  //       }
  //     }
  //   }  
  //   let relativePath = "/api/employmentagencypaymentaccount/getbyfield";
  //   // agentId
    
  //   try {
        
  //   if(agentId){
  //   let data =  { employmentAgencyId:agentId}
  //   postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       if(mydetails.length){ 
  //       setEmploymentAgencyPaymentAccounts(mydetails);  
  //       } 
  //     }
  //     else{
  //       setEmploymentAgencyPaymentAccounts([]); 
  //     }
  //     // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
      
  //   }
  //   );
  // }
  // } catch (error) {
  //     // console.log(error , "error  ")
        
  //     }


  // };

  
  // const getNewJobSeekers = (id) => {
  //   let mydetails;
  //   let agentId=id
  //   if(!id){
  //     if(agencyData){
  //     agentId=agencyData.id
  //     }
  //   } 
  //   let jobSeekersD=[]
  //   let relativePath = "/api/employmentjobseeker/search";
  //   // agentId
  //   let data = {
  //     jobSeeker: {},
  //     job: {},//jobId:223
  //     agencyJobSeeker: { employmentAgencyId:agentId},
  //   }; 
  //   postDocuments("contactus", relativePath, data, {}).then((mydetailsRes) => {
  //     try {
        
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       if(mydetails.length){
  //        mydetails = groupArray(mydetails, 'id');
  //        mydetails = Object.entries(mydetails);
  //         if(mydetails.length){
  //           mydetails.forEach(element => {
  //             let keyValue=element
  //             let allValue=[]
  //             if(keyValue.length){
  //             let allValue=keyValue[1]
  //             if(allValue.length){
  //               let value=allValue[1]
  //               value.allWorkExperiances=allValue
  //               if(value){
  //                 jobSeekersD.push(value)
  //               }
  //               }
  //             }
  //        });
  //       }
  //       setNewJobSeekers(jobSeekersD);  
  //       } 
  //     }
  //     else{
  //       setNewJobSeekers([]); 
  //     }
  //     // console.log(mydetails ,jobSeekersD , "getNewJobSeekers  ")
  //     } catch (error) {
  //     // console.log(error , "error  ")
        
  //     }
  //   });
  // };

  
  // const getAgentDataForWebsite_byId =async (agentId) => {
  //   let mydetails=null;
  //   let agencyWebsiteContentsData
  //  await getDocumentsAll(
  //     "myjobseekerinformation",
  //     "/api/employmentagency/getagentdataforwebsite/"+agentId
  //   ).then((mydetailsRes) => {
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       setAgencyData(mydetails);
  //       assignInitializeDatas(mydetails)

  //       if(mydetails.agencyWebsiteContents){
  //         agencyWebsiteContentsData=mydetails.agencyWebsiteContents
  //         agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
  //         setWebsitePagesContent(agencyWebsiteContentsData)
  //       }
  //     }
  //     else{
  //       setAgencyData(null);
  //       setWebsitePagesContent(null);
  //     }
  //       //  console.log( mydetails, "getAgentDataForWebsite")
  //     // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
  //   });
  //     return mydetails
  // };

  // const getAgentDataForWebsite_byWebsiteDomain = async() => {
  //   let mydetails;
  //   let agencyWebsiteContentsData 
  //   let originUrl=window.location.origin
  //   let isLocalHost=originUrl.indexOf("localhost")
  //   let isMegenagnaDomain=originUrl.indexOf("megenagna") 
    
  //   if((isMegenagnaDomain)){
  //       setWebsitePagesContent(websitePagesContentD)
  //   } 
  //   if((isLocalHost)){
  //       originUrl="http://extremetutors.et"///
  //       // setWebsitePagesContent(websitePagesContentD)
  //   } 
  //     //agent data
  //     // let agentId=1
  //     // let websiteDomain=""
  //     setIsAgentWebsite(true)

  //   if(originUrl){ 
  //     await postDocuments(
  //       "myjobseekerinformation",
  //       "/api/employmentagency/getagentdatabywebsitedoamin",{originUrl:originUrl}
  //     ).then((mydetailsRes) => {
  //       if (mydetailsRes.success) {
  //         mydetails = mydetailsRes.record;
  //         setAgencyData(mydetails);
  //         assignInitializeDatas(mydetails)
  //         if(mydetails.id){
  //         getEmploymentAgencyPaymentAccounts(mydetails.id)
  //         }
  //         if(mydetails.agencyWebsiteContents){
  //           agencyWebsiteContentsData=mydetails.agencyWebsiteContents
  //           agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
  //           setWebsitePagesContent(agencyWebsiteContentsData)
  //         }
  //       }
  //       else{
  //         setAgencyData(null);
  //         setWebsitePagesContent(null);
  //       }
  //         //  console.log( mydetails, "getAgentDataForWebsite")
  //       // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
  //     });
  //       return mydetails
  //     // then((mydetailsRes) => {
  //     //   if (mydetailsRes.success) {
  //     //     mydetails = mydetailsRes.record;
  //     //     setAgencyData(mydetails);
  //     //     if(mydetails.id){
  //     //     getEmploymentAgencyPaymentAccounts(mydetails.id)
  //     //     }
  //     //     if(mydetails.agencyWebsiteContents){
  //     //       agencyWebsiteContentsData=mydetails.agencyWebsiteContents
  //     //       agencyWebsiteContentsData=JSON.parse(agencyWebsiteContentsData)
  //     //       setWebsitePagesContent(agencyWebsiteContentsData)
  //     //     //  console.log( agencyWebsiteContentsData, "getAgentDataForWebsite")
  //     //     }
  //     //   }
  //     //   // console.log( agencyWebsiteContentsData, "agency Website ContentsData   ")
  //     // });

  //   } 

  // };

  // const getMyJobSeekerInformation = (agencyDataDDD) => {
  //   let mydetails;
  //   getDocumentsAll(
  //     "myjobseekerinformation",
  //     "/api/employmentjobseeker/myjobseekerinformation"
  //   ).then((mydetailsRes) => {
  //       setMyJobSeekerInformation(null);
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       setMyJobSeekerInformation(mydetails);
  //       let agencyDataD= agencyDataDDD||agencyData
  //       if(agencyDataD){
  //         if(agencyDataD.id){
  //           let dataaa = {jobseekerId:mydetails.id,employmentAgencyId:agencyDataD.id} 
  //           getMyAgencyJobSeekerInformation(dataaa);
  //         }
  //       }
  //     }
  //     // console.log( mydetails, "getCurrentUser  CurrentUserContext")
  //   });
  // };


  // const getMyAgencyJobSeekerInformation = (dataa) => {
  //   // console.log(" location.search", dataa);
  //   let res=null
  //   try {
  //     if (dataa) {
  //     let relativePath = "/api/employmentagencyjobseeker/getbyfield" 
  //     postDocuments("employmentagencyjobseeker", relativePath, dataa).then((AgencyJobSeekerInformation)=>{
  //       if (AgencyJobSeekerInformation.success) {
  //         if (AgencyJobSeekerInformation.record) {
  //           if (AgencyJobSeekerInformation.record.length) { 
  //               let agencyJobSeekerInformationD=AgencyJobSeekerInformation.record[0] 
  //               if (agencyJobSeekerInformationD) {
  //                 res=agencyJobSeekerInformationD;
  //               }  
  //           }
  //         }
  //       }
  //               setMyAgencyJobSeekerInformation(res);

  //     })
  //     }
  //     // setLoading(false);
  //   } catch (error) {}
  // };




  // const getPaymentMethods = () => {
  //   let mydetails;
  //   getDocumentsAll(
  //     "paymentMethods",
  //     "/api/paymentmethod/all"
  //   ).then((mydetailsRes) => {
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       setPaymentMethods(mydetails);
  //     }
  //     else{
  //       setPaymentMethods(null);
  //     }
  //     // console.log( mydetails, "getCurrentUser  CurrentUserContext")
  //   });
  // };

  const getMyBusinesses = ()=>{ 
    getDocumentsAll("mywebsites","/api/profile/myprofiles") .then((myWebsitesRes)=>{
      if(myWebsitesRes.success){
       if(myWebsitesRes.record){ 
       if(myWebsitesRes.record.length){ 
        setMyBusinesses(myWebsitesRes.record)
         }
       }
      }
     }
     ) 
  }

  // const getMyAgencies = () => {
  //   let mydetails;
  //   getDocumentsAll(
  //     "myjobseekerinformation",
  //     "/api/employmentjobseeker/agenciesijoined"
  //   ).then((mydetailsRes) => {
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       setMyAgencies(mydetails);
  //     }
  //     else{
  //       setMyAgencies([]);
  //     }
  //     // console.log( mydetails, "getCurrentUser  CurrentUserContext")
  //   });
  // };

  // const getEducationLevels = () => {
  //   let mydetails;
  //   getDocumentsAll("mydetails", "/api/educationLevel/all").then((mydetailsRes) => {
  //     if (mydetailsRes.success) {
  //       mydetails = mydetailsRes.record;
  //       setEducationLevels(mydetails);
  //     }
  //     else{
  //       setEducationLevels([]);
  //     }
  //     // console.log( mydetails, "getCurrentUser  CurrentUserContext")
  //   });
  // };

  // const getJobs = () => {
  //   getDocumentsAll("job", "/api/job/all").then((websiteUserTypesRes) => {
  //     //  console.log("currentloggedwebsiteRes",currentloggedwebsiteRes)
  //     if (websiteUserTypesRes.success) {
  //       if (websiteUserTypesRes.record) {
  //         setJobs(websiteUserTypesRes.record);
  //       }
  //     }
  //     else{
  //       setJobs([]);
  //     }
  //   });
  // };

  // const getUserFiles = () => {
    // let fileRelativePath = "/api/file/allFoldersAndFiles_user";
    // getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
    //   if (resdata.success) {
    //     setUserFiles(resdata.record);
    //   } 
    //   else{
    //     setUserFiles({});
    //   }
    //   // console.log( mydetails, "getCurrentUser  CurrentUserContext")
    // });
  // };

  // const getWebsiteFiles = () => {
  //   let fileRelativePath = "/api/file/allFoldersAndFiles";
  //   getDocumentsAll("getphotosalbum", fileRelativePath).then((resdata) => {
  //     if (resdata.success) {
  //       setWebsiteFiles(resdata.record);
  //     }
  //     else{
  //       setWebsiteFiles({});
  //     }
  //     // console.log( mydetails, "getCurrentUser  CurrentUserContext")
  //   });
  // };

  const getDocumentsAll = async (documentName, relativePath, additionalData) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    ////////// UPDATED/////////////
    documentsResponse = await HttpService.getService(
      relativePath,
      additionalData ? additionalData : {},
      ""
    );
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
      let record = responseD.record;
      if (documentName == "allOffices") {
        await setOfficesUIelements(record);
        DataManagementService.setLocalstorageDataSecured(record, "allOffices");
      }
    }
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };
  
  const postDocuments = async (documentName, relativePath, data) => {
    let responseD = { success: false, message: "", record: null };
    let documentsResponse = { success: false };
    // setLoading(true);

    ////////// UPDATED/////////////
    documentsResponse = await HttpService.postService(relativePath, data, {}, "");
   
    if (documentsResponse.data) {
      responseD.record = documentsResponse.data.record;
      responseD.message = documentsResponse.data.message;
    }
    if (documentsResponse.success) {
      responseD.success = true; 
    } 
    setForceRerender(!forceRerender);
    return responseD;
    ////////// UPDATED/////////////
  };


  async function updateCurrentUser(variableName) {
    if (variableName) {
      if (variableName == "currentUser") {
        getCurrentUser();
      }
      // if (variableName == "myAgencyJobSeekerInformation") {
      //   getMyAgencyJobSeekerInformation();
      // }
      // if (variableName == "myAgencies") {
      //   getMyAgencies();
      // }
      // if(variableName=="myBusinesses"){
      //   getMyBusinesses() 
      // } 
      // if (variableName == "newJobSeekers") {
      //   getNewJobSeekers();
      // }
      // if (variableName == "paymentMethods") {
      //   getPaymentMethods();
      // }
      // if (variableName == "employmentAgencyPaymentAccounts") {
      //   setEmploymentAgencyPaymentAccounts();
      // }
      // if (variableName == "websiteFiles") {
      //   getWebsiteFiles();
      // }
      // if (variableName == "userFiles") {
      //   getUserFiles();
      // }
      // if (variableName == "jobs") {
      //   getJobs();
      // }
      // if (variableName == "myJobSeekerInformation") {
      //   getMyJobSeekerInformation();
      // }
    } else {
      getStarted();
    }
    // await getDocumentsAll("allOffices","/api/officeUserRole/myoffices",{})
    // await getDocumentsAll("currentloggedwebsite","/api/auth/currentloggedwebsite")
  }

  return (
    <CurrentUserContext.Provider
      value={[
        {
          paymentMethods: paymentMethods,
          employmentAgencyPaymentAccounts: employmentAgencyPaymentAccounts,
          myAgencies: myAgencies,
          myBusinesses: myBusinesses,
          myAgencyJobSeekerInformation: myAgencyJobSeekerInformation,
          newJobSeekers: newJobSeekers,
          websitePagesContent: websitePagesContent,
          isAgentWebsite: isAgentWebsite,
          agencyData: agencyData,
          educationLevels: educationLevels,
          isUserLoggedIn: isUserLoggedIn,
          currentUser: currentUser,
          userFiles: userFiles,
          jobs: jobs,
          websiteFiles: websiteFiles,
          forceRerenderContext: forceRerender,
          myJobSeekerInformation: myJobSeekerInformation,
        },
        updateCurrentUser,
      ]}
      {...props}
    />
  );
}

function useCurrentUser() {
  //for pages that want to access the context object's value
  //custom hook use...
  try {
    const context = useContext(CurrentUserContext);
    // console.log('  inside the Provider',context);
    // if (!context) throw new Error('Not inside the Provider');
    return context; // [fav, updateFav]
  } catch (err) {}
}

export { useCurrentUser, CurrentUserProvider };
