 
import SignUpCoverPage from "layouts/authentication/sign-up/cover";
import ResetPasswordPage from "layouts/authentication/reset-password/cover";
import SignInBasicPage from "layouts/authentication/sign-in/basic";
import BusinessLogin from "pages/Authentication/SignIn/BusinessLogin";
import VerifyUserCreation from "pages/Authentication/VerifyUserCreation";
import ForgetPassword from "pages/Authentication/ForgetPassword";

 

const customRoutes = [
   
  {
    name: "Login",
    route: "/login",
    component: <SignInBasicPage />,
  },
  
  
  {
    name: "Business Login",
    route: "/business-login",
    component: <BusinessLogin />,
  },
  {
    name: "Register",
    route: "/register",
    component: <SignUpCoverPage />,
  },
  {
    name: "Register",
    route: "/verify-user-creation",
    component: <VerifyUserCreation />,
  },
   
  {
    name: "reset password" ,
    route: "/reset-password",
    component: <ResetPasswordPage />,
  },
  
  {
    name: "forget password" ,
    route: "/forget-password",
    component: <ForgetPassword />,
  },
  
  
];

export default customRoutes;
